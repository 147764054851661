import React, {useEffect} from 'react'
import styles from './PrivacyPolicy.module.scss'
export function PrivacyPolicy () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h1>私隱政策</h1>
        <h3>I. 定義</h3>
        <h4>於以下私隱條款</h4>
        <ul>
            <li>「慧擇香港」，「我們」或「我們的」代表慧擇香港</li>
            <li>「平台」代表我們營運的數碼渠道，包括<a href="https://www.facebook.com/Huize-Hong-Kong">https://www.facebook.com/Huize-Hong-Kong</a>  (「Facebook 專頁」)</li>
            <li>「你」，「你的」或「用戶」代表接觸及使用本平台的人士</li>
        </ul>
        <h3>II. 接受本政策</h3>
        <p>慧擇香港非常重視並尊重用戶的個人資料，並會致力確保用戶的個人資料和/或數據得到保障。</p>
        <p>我們不會向用戶收集不必要、能辨別其身分的個人資料，除非收集有關個人資料乃出於閣下自願，讓您使用本平台。當您向我們提交個人資料時，即表示您已閱讀並理解本私隱政策，並且，您同意我們收集和使用您的個人資料用於本政策中描述的所有目的。</p>
        <p>除非您明確同意，我們不會將您的個人資料﹙當中包括您的姓名和聯繫方式﹚給予第三方作直接營銷之用。</p>
        <h3>III. 收集個人資料的目的</h3>
        <p>我們收集個人資料，為了包括但不限於以下目的：</p>
        <ul>
            <li>爲用戶提供日常服務</li>
            <li>驗證用戶的身分，以使用本平台部分指定的服務</li>
            <li>處理詢問、意見和投訴</li>
            <li>聯絡用戶關於慧擇香港服務或帳戶的通知及資訊</li>
            <li>經彙總整理統計後，分析用戶的活動情況，讓我們了解用戶的興趣，提升本平台的內容質素</li>
            <li>向您發出內容或市場推廣資訊</li>
            <li>與上述目的有關的任何其他目的</li>
        </ul>
        <p>除了「資料披露」部分列出的情況外，慧擇香港不會將收集的個人資料和/或數據傳遞給第三方。</p>
        <h3>IV. 我們會收集的資料</h3>
        <p>根據我們需要為您提供的不同服務，我們會收集的資料包括 （但不限於）：</p>
        <ul>
            <li>姓名，暱稱，性別等個人資料</li>
            <li>聯絡資料 （如電話號碼、電郵地址）</li>
            <li>不能辨別個人身分的資料，例如使用行為、用戶偏好、線上活動數據。</li>
        </ul>
        <h3>V. 資料披露</h3>
        <p>慧擇香港收集的資料只供上述用途。但是，於以下情況，我們可能需要披露上述資料：</p>
        <ul>
            <li>慧擇香港可能使用、儲存、或轉移你的會員資料至第三方或本平台之合作夥伴，以提供服務或代表我們進行某些商業活動。</li>
            <li>慧擇香港為處理、執行一般工作﹙包括但不限於服務傳送、數據管理等﹚而且符合收集資料的目的，並須以保密方式向服務供應商披露我們收集的資料；</li>
            <li>遵守任何對慧擇香港有約束力的法律或監管機構的適用規則、法律條例；</li>
            <li>其他法律目的（包括但不限於配合執法和解決糾紛）</li>
        </ul>
        <h3>VI. 資料保護</h3>
        <p>我們會採取一切實際可行方法確保個人資料的安全，以及避免個人資料在未經授權或意外的情況下被取用、刪除或作其他用途。這包括在實物具體上、技術上及程序上的適當安全措施，確保個人資料存放於安全的位置，只有經授權人士才能接觸有關資料。</p>
        <p>然而，由於網絡的性質，我們不能保證數據儲存或傳遞絕對安全，亦不能保證沒有未經授權的第三者入侵電腦，竊取資料，或擅自取閱資料。</p>
        <h3>VII. 連接其他網站的連結</h3>
        <p>為了給我們的用戶提供更好的服務，我們的網站或應用程式可能會包含連接其他網站的連結。不過，這些網站並非由慧擇香港運營，我們不能控制其他網站。 故此，如果閣下瀏覽那些網站，並提供任何資料，將受其隱私政策的約束， 您應該閱讀他們的隱私和其他聲明。我們概不負責該等資料的保護及私隱事宜。</p>
        <h3>VIII. 查閱及改正資料要求</h3>
        <p>根據《私隱條例》，慧擇香港用戶有權查閱、獲取及更改您向我們提供的個人資料。</p>
        <p>如欲獲取您的個人資料，或對此私隱聲明有任何疑問或意見，請聯絡我們的客戶服務部﹕5934 2369</p>
        <p>慧擇香港客戶服務部經理電郵地址﹕ Alvin Au</p>
        <p>慧擇香港保留隨時更改及更新此私隱聲明的權利。根據有關個人資料(私隱)條例的規定，本公司有權對查閱或更正個人資料的要求收取合理的費用。</p>
        <p>更新日期﹕ 2021年6月9日</p>
      </div>
    </div>
  )
}