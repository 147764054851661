import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Footer} from '../../components'
import { Form, Select, Button, message } from 'antd'
import style from './CompareInsurance.module.scss'
import { getInsurancePolicyType } from '../../services/api'
import { selectArrow } from '../../assets'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import  SwiperCore, { Autoplay } from 'swiper/core';
SwiperCore.use([Autoplay]);
const btnStyle = {
  width: '148px',
  height: '46px',
  background: '#F2A915',
  lineHeight: '14px',
  fontSize: '14px',
  fontFamily: 'SourceHanSansCN-Medium',
  fontWeight: '500',
  color: '#FFFFFF',
  borderColor: 'transparent'
}
export function CompareInsurance () {
  const history = useHistory()
  const [category, setCategory] = useState([])
  const fetchInsurancePolicyType = () => {
    getInsurancePolicyType({
      level: 2
    }).then(res => {
      if (res.data.code === 0) {
        setCategory(res.data.data ? res.data.data : [])
      } else {
        message.error(res.data.message)
      }
    }).catch(e =>{
      message.error(e.data.message)
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchInsurancePolicyType()
  }, [])
  
  const onFinish = (values) => {
    
    const item = category[Number(values.type)]
    const jsonStr = JSON.stringify(item)
    const ecodeStr = window.btoa(window.encodeURIComponent(jsonStr))
    // const ecodeStr = window.escape(jsonStr)
    // console.log(window.encodeURIComponent(jsonStr))
    if (ecodeStr) {
      history.push(`/compare-product/${ecodeStr}`)
    }
  }
  return <div className={style['compare-container']}>
    <div className={style['banner-box']}>
      <p className={style['title']}>產品介紹</p>
      <p className={style['desc']}>保險種類五花八門，各有不同的保障範圍、產品特色，令人眼花撩亂。我們幫您在相應的險種中，細心比較保險產品。</p>
    </div>

    <div className={style['main']}>
      <div className={style['select-compare']}>
        <span className={style['title']}>保險比較</span>
        <div className={style['form-container']}>
          <span className={style['form-label']}>我想比較</span>
          <Form style={{width: '100%'}}
            onFinish={onFinish}
          >
            <Form.Item
              name="type"
              rules={[{ required: true, message: '請選擇你想比較的保險分類' }]}
            >
              <Select size={'large'} placeholder='請選擇你想比較的保險分類' suffixIcon={<img src={selectArrow} alt=''/>} onChange={value => {
                console.log(value)
              }}>
                {
                  category.map((el, idx) => {
                    return <Select.Option value={idx} key={idx}>{el.name}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item style={{ marginTop: '32px',textAlign: 'center'}}>
              <Button style={btnStyle} htmlType="submit">立即比較</Button>
            </Form.Item>
          </Form>
        </div>
        <div className={style['annuity-insurers']}>
          <Swiper
            style={{width: '100%', height: '100%'}}
            initialSlide={0}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false
            }}
            loop={true}
            spaceBetween={16}
            slidesPerView={5}
          >
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
    <Footer />
  </div>
}