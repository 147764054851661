// import { createStore, combineReducers, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import languageReducer from './language/languageReducer'
import activityListReducer from './activityList/activityListReducer'
import { activityDetailSlice } from './activityDetail/slice'
import { newsListSlice } from './news/slice'
import { bannerSlice } from './banner/slice'
import { userInfoSlice } from './user/slice'
import { switchFontScale } from './fontScale/slice'
// combineReducers()
const rootReducer = combineReducers({
  language: languageReducer,
  activityList: activityListReducer,
  activityDetail: activityDetailSlice.reducer,
  newsList: newsListSlice.reducer,
  bannerList: bannerSlice.reducer,
  user: userInfoSlice.reducer,
  font: switchFontScale.reducer
})
// 使用中间件的目的就是能派发一个函数的action
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
});
// const store = createStore(rootReducer, applyMiddleware(thunk)) 
// redux中间件公式： const middleware = (store) => (next) => (action) => {}
export default store
