import { K_FETCH_ACTIVITY_LIST_START, K_FETCH_ACTIVITY_LIST_SUCCESS, K_FETCH_ACTIVITY_LIST_FAIL} from './activityListAction'
const defaultState = {
  data: {},
  loading: true,
  errMsg: ''
}

const activityListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case K_FETCH_ACTIVITY_LIST_START:
      return {...state, loading: true}
    case K_FETCH_ACTIVITY_LIST_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case K_FETCH_ACTIVITY_LIST_FAIL:
      return {...state, loading: false, errMsg: action.payload}
    default:
      return state
  }
}

export default activityListReducer