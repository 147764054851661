/**
   * 存储localStorage
   */
export const setStore = (name, content) =>{
  if (!name) return
  // if (typeof content !== 'string') {
  //   content = JSON.stringify(content);
  // }
  window.localStorage.setItem(name, content);
}
/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  // const jsonStr = window.localStorage.getItem(name)
  return window.localStorage.getItem(name)
}
/**
 * 清除localStorage
 */
 export const clearStore = () => {
  window.localStorage.clear();
}

export const getQueryStringByName = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  var context = "";
  if (r != null)
    context = r[2];
  reg = null;
  r = null;
  return context == null || context === "" || context === "undefined" ? "" : context;
}

export const resetRoute = () => (window.location.href = window.location.origin);

export const  splitArray = (array, subGroupLength) => {
  let index = 0
  let newArray = []
  while(index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}

export const loadScript = ( url, callback ) => {
  var script = document.createElement('script'),
      fn = callback || function(){};
  script.type = 'text/javascript'
  script.crossOrigin = 'anonymous'
  script.async = true
  script.defer = true
  if(script.readyState){
    script.onreadystatechange = () => {
      if( script.readyState === 'loaded' || script.readyState === 'complete' ){
        script.onreadystatechange = null;
        fn('success');
      }
    }
  }else{
    //其他浏览器
    script.onload = () => {
      fn('success');
    };
    script.onerror = () => {
      fn('fail');
    }
  }
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
}

export const isLegalEmail = (email) => {
  // 
  let checkEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
  // /^\w+(?:\.\w+){0,1}@@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
  console.log(checkEmail.test(email))
  return checkEmail.test(email)
}

export const isLegalChinaPhone = (num) => {
  // let zhCNRegular = /^((13[0-9])|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[3,5,6,7,8])" +
  // "|(18[0-9])|(19[8,9]))\\d{8}$/
  let zhCNReg = /^(\+?0?86\-?)?1[345789]\d{9}$/
  return zhCNReg.test(num)
}

export const isLegalHKPhone = (num) => {
  // let HKReg = /^(\+?0?86\-?)?1[345789]\d{9}$/
  // /^(\+852)[6|9]\d{7}$/
  // /^(\+852)(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/
  // http://www.qilin668.com/a/5e53d0c43a2852k.html
  let HKReg = /^(\+852)?(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/
  
  return HKReg.test(num)
}