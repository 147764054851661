import http from './http'
import { K_LOGIN_TOKEN } from '../utils/constants'
import { getStore } from '../utils/index'
const getActivityList = (data) => {
  return http.post('activity/list', data)
}
const getActivityDetail = (data) => {
  return http.post('activity/detail', data)
}
const getActivityCategoryList = () => {
  return http.post('activity/categoryList', {})
}

const getNewsList = (data) => {
  return http.post('news/list', data)
}

const getNewsDetail = (data) => {
  return http.post('news/detail', data)
}

const getBannerList = () => {
  return http.post('promo/list', {})
}

const submitCustomerReserve = (data) => {
  return http.post('customerReserve/submit', data)
}

// 注册，发送验证码
const sendRegisterCode = (data) => {
  return http.post('user/sendRegisterCode', data)
}

// 手机号注册
const registerByMobile = (data) => {
  return http.post('user/registerByMobile', data)
}

// 手机号登录
const loginByMobile = (data) => {
  return http.post('user/login', data)
}

const thirdPartyLogin = (data) => {
  return http.post(data.type === 'google' ? '/user/loginWithGoogle' : '/user/loginWithFacebook', data.param)
}

const forgetPassword = (data) => {
  return http.post('user/sendForgetPassCode', data)
}

const getUserInfo  = () => {
  const token = getStore(K_LOGIN_TOKEN)
  return http.post('user/detail', {token})
}

const getInsurancePolicyType  = (data) => {
  return http.post('common/insuranceCategoryList', data)
}

const getInsuranceBrandList  = () => {
  return http.post('common/insuranceBrandList', {})
}

const searchInsuranceProduct  = (data) => {
  return http.post('insurance/search', data)
}

const uploadInsurancePolicy  = (data) => {
  const token = getStore(K_LOGIN_TOKEN)
  return http.post('insurancePolicyCustody/upload', data, { 'Content-Type': 'multipart/form-data', token})
}

const compareInsurance = (data) => {
  return http.post('insurance/compare', data)
}

export {
  getActivityList,
  getActivityCategoryList,
  getActivityDetail,
  getNewsList,
  getNewsDetail,
  getBannerList,
  submitCustomerReserve,
  sendRegisterCode,
  registerByMobile,
  loginByMobile,
  thirdPartyLogin,
  forgetPassword,
  getUserInfo,
  getInsurancePolicyType,
  uploadInsurancePolicy,
  getInsuranceBrandList,
  searchInsuranceProduct,
  compareInsurance
}
