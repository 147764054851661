import i18 from 'i18next'
import { CHANGE_LANGUAGE, ADD_LANGUAGE } from './languageActions'
const defaultState = {
  language: 'zh',
  languageList: [
    {
      name: '中文',
      code: 'zh'
    },
    {
      name: 'English',
      code: 'en'
    }
  ]
}
const languageReducer = (state = defaultState, action) => {
  switch(action.type) {
    case CHANGE_LANGUAGE:
      i18.changeLanguage(action.payload)
      return { ...state, language: action.payload }
    case ADD_LANGUAGE:
      return { ...state, languageList: [...state.languageList, action.payload] }  
    default:  
      return state
  }
}

export default languageReducer
