import { BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import { PrivacyPolicy, PersonalInformation, ComingSoon, NotFound} from './pages'
import './App.css';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={ComingSoon} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/personal-information" exact component={PersonalInformation} />
          <Route component={NotFound}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App
