// import { useLocation } from 'react-router-dom'
import axios from 'axios'
// 1. 创建新的axios实例，
// const baseUrl = process.env.VUE_APP_BASE_URL
// let baseUrl = ''
// if (process.env.NODE_ENV === 'production') {
//   baseUrl = 'https://wxa-api.huixinguanli.cn'
// } else if (process.env.NODE_ENV === 'development') {
//   baseUrl = '/api'
// }
// http://120.78.208.153:8060/
const baseUrl = process.env.REACT_APP_API_URLL

const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: baseUrl,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 6 * 1000,
  // headers: {
  //   'content-Type': 'application/json',
  // }
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  return config
}, e => {
  Promise.reject(e)
})
// 3.响应拦截器
service.interceptors.response.use(config => {
  // window.location.href = '/login'
  return config
}, e => {
  // const { error } = e.response.data
  // console.log(error)
  return Promise.reject(e.response.data)
})
export default service
