export const CHANGE_LANGUAGE = "change_language"
export const ADD_LANGUAGE = "add_language"

export const changeLanguageActionCreator = (code) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: code,
  }
}

export const addLanguageActionCreator = (name, code) => {
  return {
    type: ADD_LANGUAGE,
    payload: { name, code },
  }
}