import React from 'react'
import styles from './ComingSoon.module.scss'
import { assetsUrl } from '../../utils/constants'
export function ComingSoon () {
  return (
    <div className={styles['coming-soon-container']}>
      <img className={styles['coming-soon-banner1']} src={assetsUrl + 'images/icon_coming_soon_banner1.png'} alt='' />
      {/* <div className={styles['banner-container']}>

      </div> */}
      <img className={styles['coming-soon-banner2']} src={assetsUrl + 'images/icon_coming_soon_banner2.png'} alt='' />
      <div className={styles['banner-container']}>
        <img className={styles['coming-soon-banner3']} src={assetsUrl + 'images/icon_coming_soon_banner3.png'} alt='' />
      </div>
    </div>
  )
}
