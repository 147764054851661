import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBannerList } from '../../services/api'
const initialState = {
  data: null,
  loading: true,
  errMsg: ''
}

export const fetchBannerList = createAsyncThunk(
  "banner/getBannerList",
  async () => {
    const { data } = await getBannerList()
    return data
  }
)

export const bannerSlice = createSlice({
  name: "bannerList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBannerList.pending.type]: (state) => {
      // return { ...state, loading: true };
      state.loading = true
    },
    [fetchBannerList.fulfilled.type]: (state, action) => {
      if (action.payload.code === 0) {
        state.errMsg = ''
        state.data = action.payload.data
      } else {
        state.errMsg = action.payload.message
        state.data = action.payload.data
      }
      state.loading = false
    },
    [fetchBannerList.rejected.type]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    }
  }
})