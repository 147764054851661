import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  scale: 1
}

export const switchFontScale = createSlice({
  name: "switchFontScale",
  initialState,
  reducers: {
    setFontScale: (state, action) => {
      state.scale = action.payload
    }
  }
})

export const { setFontScale } = switchFontScale.actions