import React from 'react'
import styles from'./NotFound.module.scss'
export function NotFound () {
  return (
    <div className={styles['notfound-container']}>
      <p className={styles['title']}>找不到網頁</p>
      <p className={styles['sub-title']}>您要查找的頁面可能地址不正確或已被存檔。</p>
    </div>
  )
}
