import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo } from '../../services/api'
const initialState = {
  data: null,
  loading: true,
  errMsg: ''
}

export const fetchUserInfo = createAsyncThunk(
  "userInfo/getUserInfo",
  async () => {
    const { data } = await getUserInfo()
    return data
  }
)

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload
    },
    getUserData: (state) => {
      return state
    }
  },
  extraReducers: {
    [fetchUserInfo.pending.type]: (state) => {
      state.loading = true
    },
    [fetchUserInfo.fulfilled.type]: (state, action) => {
      if (action.payload.code === 0) {
        state.errMsg = ''
        state.data = action.payload.data
      } else {
        state.errMsg = action.payload.message
        state.data = action.payload.data
      }
      state.loading = false
    },
    [fetchUserInfo.rejected.type]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    }
  }
})

export const { setUserData, getUserData } = userInfoSlice.actions