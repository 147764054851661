import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getActivityDetail } from '../../services/api'
const initialState = {
  data: null,
  loading: true,
  errMsg: ''
}

export const fetchActivityDetail = createAsyncThunk(
  "activityDetail/getActivityDetail",
  async (id) => {
    const { data } = await getActivityDetail({id})
    return data
  }
)

export const activityDetailSlice = createSlice({
  name: "activityDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchActivityDetail.pending.type]: (state) => {
      // return { ...state, loading: true };
      state.loading = true
    },
    [fetchActivityDetail.fulfilled.type]: (state, action) => {
      console.log(action.payload)
      if (action.payload.code === 0) {
        state.errMsg = ''
        state.data = action.payload.data
      } else {
        state.errMsg = action.payload.message
        state.data = null
      }
      state.loading = false
    },
    [fetchActivityDetail.rejected.type]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload.message;
    },
  }
})